import React from 'react';
import { connect } from 'react-redux';
import DKRouter from '../routers/dk-router';
import { TopSection } from 'ui/FixedFullScreen';
import ContentWrapper from './sections/ContentWrapper';
import AppToolbar from './sections/AppToolbar';
import Modals from './sections/Modals';
import Footer from './sections/Footer';
import BetslipNotifier from 'features/BetSlip/components/BetslipNotifier';
import SystemHandler from 'features/SystemHandler';
import Feedback from 'layouts/sections/Mobile/Feedback';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { intercomBoot } from 'utils/trackers';
import userConductor from 'common/conductors/UserConductor';
import SpilklubButton from 'features/Spilklub/Mobile/SpilklubButton';
import { HIDE_MOBILE_TOOLBAR_FOR_PATH, NEW_TRACK_PAGE } from 'configs/main';
import BetBuddyEntry from 'features/Spilklub/Mobile';
import { isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';
import BottomMenuWrapped from './sections/Mobile/BottomMenu';
import Highlights from 'layouts/sections/Highlights';
import { FixedContainer, BetslipNotifierContainer } from 'features/TrackPage/components/styled';

const PageWrapper = styled.div`
    flex: 1;
    position: relative;
    ${({ background }) => background && `background-color: ${background}`};
`;

class Mobile extends React.Component {
    componentDidMount() {
        let prevLocation = ''; // needed because of multiple calls of history.push inside navigateToRaceDay

        if (window.location.hash === '#TWA') {
            window.localStorage.setItem('isTWA', 'true');
        }

        const pushPageView = () =>
            window.dataLayer.push({
                event: 'page_view',
                eventUrl: window.location.pathname,
                type: 'heste',
                userId: this.props?.userId || 0,
            });

        this.props.history.listen(location => {
            if (prevLocation !== location.pathname) {
                if (window.dataLayer) {
                    pushPageView();
                }
                prevLocation = location.pathname;
            }
        });

        const interval = setInterval(() => {
            if (!this.props.authPending) {
                if (window.dataLayer) {
                    pushPageView();
                    prevLocation = this.props?.history?.location?.pathname;
                }
                clearInterval(interval);
            }
        }, 1000);

        intercomBoot();
        userConductor.registerUserDeviceInCMS();
    }

    render() {
        const { location } = this.props;

        const showAppToolbar = !HIDE_MOBILE_TOOLBAR_FOR_PATH.includes(
            location.pathname.toLowerCase().split('/')[1]
        );

        const highlights = this.props.showHighlight ? <Highlights mobile /> : null;

        return (
            <>
                <ContentWrapper className="mobile-layout" showAppToolbar={showAppToolbar}>
                    {showAppToolbar && (
                        <TopSection>
                            <AppToolbar />
                        </TopSection>
                    )}

                    <PageWrapper background="#f0f2f8">
                        {highlights}
                        <DKRouter />
                    </PageWrapper>

                    <Feedback />

                    <Footer />

                    {!NEW_TRACK_PAGE && (
                        <FixedContainer>
                            <BetslipNotifierContainer>
                                {!this.props.spilklubSelectionsMode && <BetslipNotifier />}
                            </BetslipNotifierContainer>
                            {this.props.isVProduct && <SpilklubButton />}
                        </FixedContainer>
                    )}

                    <BottomMenuWrapped />

                    <Modals />

                    <BetBuddyEntry />

                    <SystemHandler />
                </ContentWrapper>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        userId: state.auth?.user?.id,
        authPending: state.auth?.pending,
        spilklubSelectionsMode: isSpilklubSelectionsMode(state),
        showHighlight: state.ui.showHighlight,
    };
};

export default withRouter(connect(mapStateToProps)(Mobile));
