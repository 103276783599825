import React from 'react';
import { RaceLineNavigationButton } from 'ui/RaceNavigation';
import Text from 'ui/Text';

const RaceLine = ({ raceNumbers, raceIndex, setRaceIndex, fadedRaceNumbers, isVProduct, ...props }) => {
    return raceNumbers.map((raceNumber, index) => {
        return (
            <RaceLineNavigationButton
                onClick={() => setRaceIndex(raceNumbers.indexOf(raceNumber))}
                active={raceIndex === index}
                accent={fadedRaceNumbers.length > 0 && fadedRaceNumbers.includes(raceNumber)}
                key={index}
                {...(raceIndex === index ? { ref: props.innerRef } : {})}
            >
                <Text padding={'0'} size={'x1'} weight={'900'} color={'inherit'}>
                    {isVProduct ? 'Afd.' : 'Løb'}
                </Text>
                <Text padding={'0'} size={'x1'} weight={'900'} color={'inherit'}>
                    {raceNumber}
                </Text>
            </RaceLineNavigationButton>
        );
    });
};

export default RaceLine;
