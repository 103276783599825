import React from 'react';
import Product from './Product';
import Spinner from 'ui/Spinner';
import Wrapper from 'ui/Wrapper';
import * as RaceNavigation from 'ui/RaceNavigation';
import { byPlatform, forMobile } from 'utils/platforms';
import { NEW_TRACK_PAGE } from 'configs/main';

class Products extends React.Component {
    scrollContainerRef = React.createRef();
    activeButtonRef = React.createRef();

    componentDidMount() {
        this.scrollToActiveProductButton();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedProductId !== this.props.selectedProductId) {
            this.scrollToActiveProductButton();
        }
    }

    scrollToActiveProductButton = () => {
        const scrollBarElement = this.scrollContainerRef.current;
        const activeButtonRect = this.activeButtonRef.current?.getBoundingClientRect();
        scrollBarElement.scrollLeft = activeButtonRect?.left - activeButtonRect?.width / 2 || 0;
    };

    onSelectHandler = (event, product) => {
        this.props.onSelect(product);
    };

    render() {
        const { data, selectedProductId } = this.props;

        return data ? (
            <RaceNavigation.ScrollBar
                ref={this.scrollContainerRef}
                data-test-id="products-section"
                style={byPlatform({}, { marginTop: '-7px' })}
            >
                {data.map((item, index) => {
                    const active = selectedProductId === item.id;
                    return (
                        <Product
                            onSelect={this.onSelectHandler}
                            active={active}
                            disabled={item.disabled}
                            key={index}
                            product={item}
                            legacyMode={!NEW_TRACK_PAGE}
                            last={index + 1 === this.props.data.length}
                            // not a styled component innerRef passing! {innerRef} prop is just alternative to forwardRef
                            {...(active ? { innerRef: this.activeButtonRef } : {})}
                        />
                    );
                })}
                {/* fixes Mob. Padding after the last button DER-2629 (padding-right doesn't work for mobile) */}
                {forMobile(<RaceNavigation.ScrollbarRightIndent />)}
            </RaceNavigation.ScrollBar>
        ) : (
            <Wrapper>
                <Spinner />
            </Wrapper>
        );
    }
}

export default Products;
