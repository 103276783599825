import { useEffect, useState } from 'react';
import AISREST from 'features/AISDataProvider/AISREST';
import Unserializer from '../Unserializer';
import RacingCard from 'common/DataObjects/RacingCard';

interface UseRacingCard {
    racingCard: RacingCard;
    racingCardPending: boolean;
}

const useRacingCard = ({
    productId,
    trackId,
    date,
}: {
    productId?: string;
    trackId?: number;
    date?: string;
}): UseRacingCard => {
    const [racingCard, setRacingCard] = useState<RacingCard>(new RacingCard());
    const [racingCardPending, setPending] = useState<boolean>(false);

    useEffect(() => {
        if (!productId || !trackId || !date) {
            return;
        }

        setPending(true);

        Promise.all([
            AISREST.fetchRacingCard(productId, date, trackId),
            AISREST.fetchTrackPool(date, trackId),
        ])
            .then(([racingCardResponse, trackPoolResponse]) => {
                if (!racingCardResponse.success || !trackPoolResponse.success) {
                    return Promise.reject([racingCardResponse.error, trackPoolResponse.error]);
                }

                const racingCard = new Unserializer(racingCardResponse.data).getRacingCard(
                    trackPoolResponse.data
                );

                setRacingCard(racingCard);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => setPending(false));
    }, [productId, trackId, date]);

    return { racingCard, racingCardPending };
};

export default useRacingCard;
